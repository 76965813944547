.output-area{
    position: relative;
    min-height: 200px;
    background-color: #fff;
}

@media only screen and (min-width: 769px) {
    .output-area {
      background: none;
        /* background-image: url("../../img/shutterstock_marble_min.jpg"); */
    }
  }