.info{
    position: fixed;
    width: 100%;
    transition: transform 0.5s ease-out;
    transform: translateY(-100%);
    background-color:	#9f8170	;
    height: 200px;
    z-index: 2;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 2px dotted brown;
}

.info-open {
    transform: translateY(0%);
}

.info-section {
  padding: 40px;
}

.info-section h2 {
  color: white;
  text-align: center;
}

.info-close {
  color: white;
  font-size: 40px;
  cursor: pointer;
}

.info-close:hover {
  font-size: 44px;
}
