.menu {
  width: 100%;
  display: grid;
  grid-template-columns: 64px 1fr 64px;
  box-sizing: border-box;
  padding: 20px 40px;
}

.info-icon-wrapper, .notes-icon-wrapper {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.notes-icon {
  width: 48px;
  height: 48px;
  color: grey;
}

.notes-icon:hover {
  width: 52px;
  height: 52px;
  color: orange;
  cursor: pointer;
}

.info-icon {
  width: 40px;
  height: 40px;
  color: grey;
}

.info-icon:hover {
  width: 44px;
  height: 44px;
  cursor: pointer;
}
