.conversion-node {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 80px;
}

.node-text {
    color: white;
    padding: 5px;
}

.node-unit {
    color: black;
}

.node-text h2 {
    font-size: 30px;
    font-weight: 200;
    padding: 2px;
}

.node-unit {
    border-bottom: 2px solid black;
}

@media only screen and (min-width: 769px) {

    /*
    Goal: make these 200px across,
    but make the number text change
    size so it always fits
    */

    .conversion-node {
        margin: 10px;
        width: 360px;
        height: 200px;
        box-shadow: 0px 7px rgba(0,0,0,0.1);
    }


}
