.conversion-list {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}

@media only screen and (min-width: 769px) {
    .conversion-list {
        flex-direction: row;
        justify-content: space-evenly;
        padding: 50px 0px;
    }
}