.App {
  text-align: center;
}

:root {
  background-color: #F5F5DC;
}

@media only screen and (min-width: 769px) {

  .global-bg {
    /*background-image: url("./img/shutterstock_marble_min.jpg");*/
  }

}
