.input-area{
    position: relative;
    display: grid;
    grid-template-areas:
    "select select select unit unit";
    align-items: flex-end;
    min-height: 120px;
    padding-right: 10px;
}

.form {
  height: 100%;
  text-align: center;
}

@media only screen and (min-width: 500px) {

  .input-area {
    grid-template-areas:
    "select select unit";
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1201px) {

  .input-area {
    grid-template-areas:
    "select unit";
  }
}
